/**
* 判断目前运行平台的方法
*/
export function isPC() {
  let userAgentInfo = navigator.userAgent;
  let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > -1) {
        flag = false;
        break;
    }
  }
  return flag;
}

/**
* 判断是否为Android平台
*/
export function isAndroid() {
  let userAgentInfo = navigator.userAgent;
  let Agents = ["Android"];
  let flag = false;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > -1) {
        flag = true;
        break;
    }
  }
  return flag;
}

/**
* 判断目前运行平台是否为微信浏览器
*/
export function isWechat() {
  return /MicroMessenger/i.test(window.navigator.userAgent);
}