<template>
  <div class="video-inline" :id="`videobox_${videoId}`">
    <video
      :ref="videoId"
      :id="videoId"
      x5-playsinline
      playsinline="true"
      webkit-playsinline="true"
      x-webkit-airplay="true"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="true"
      x5-video-orientation="portraint"
      :style="{borderRadius: borderRadius, objectRit: 'fill'}"
    ></video>
  </div>
</template>

<script>
import { isPC, isWechat, isAndroid } from "@/utils";
const defaultVideoOption = {
  autoplay: "false",
  loop: true,
  preload: "auto",
  controls: false,
  muted: true,
  sources: [
    {
      src: "",
    },
  ],
};
export default {
  name: "VideoInline",
  props: ["videoId", "videoUrl", "borderRadius"],
  data() {
    return {
      player: null,
      observer: null,
      videojsOption: null,
      videoDom: null,
      playPromise: null
    };
  },
  watch: {
    "$store.state.WeixinJSBridgeReady": {
      handler(n, o) {
        if (n && isWechat() && !isPC()) {
          this.initPlayer();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!isWechat() || this.$store.state.WeixinJSBridgeReady || isPC()) {
        this.initPlayer();
      }
    });
  },
  beforeDestroy() {
      this.destoryVideo()
  },
  methods: {
    initPlayer() {
      this.videojsOption = JSON.parse(JSON.stringify(defaultVideoOption));
      this.videojsOption.sources[0].src = this.videoUrl;
      this.player = this.$videojs(this.videoId, this.videojsOption, () => {
        console.log(`播放器 ${this.videoId} 初始化完成`)
        this.addObserverVideo();
      });
    },
    // 摧毁视频
    destoryVideo() {
      // video.js同一个id只能初始化一次，但是在vue中，一个组建被重复渲染是一件很平常的事情，因此在vue中的video.js很容易遇到VIDEOJS: WARN: this.player “id_video” is already initialised. Options will not be applied.这个报错，解决方案就是在适当的时候调用dispose()方法销毁video.js对象：
      if ( this.player && this.$refs[this.videoId]) {
        this.observer.unobserve(this.videoDom)
        this.observer = null
        this.player.dispose();
        this.player = null;
        this.playPromise = null
      }
    },
    // 只有在视窗内才可播放视频
    addObserverVideo() {
      if(this.observer) {
        return
      }
      // const ovserverOption = {
      //   root: null, // 未指定或者为null，则默认为浏览器视窗。
      //   rootMargin: '0px',
      //   threshold: 0.25 // 当target元素的在root元素中的可见性超过 25% 的时候，回调函数将会被执行
      // }
      this.observer = new IntersectionObserver((entries) => {
        for (let i = 0, len = entries.length; i < len; i++) {
          if (this.player) {
            if (entries[i].isIntersecting) {
              console.log("播放", this.videoId);
              this.playPromise = this.player.play();
            } else {
              console.log("暂停", this.videoId);
              this.playPromise && this.player.pause();
            }
          }
        }
      });
      this.videoDom = document.getElementById(this.videoId);
      this.observer.observe(this.videoDom);
    },
  },
};
</script>

<style lang="scss" scoped>
.video-inline {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  video {
    height: 100%;
    width: 100%;
  }
  & > div {
    height: 100%;
    width: 100%;
  }
}
</style>