import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import About from '../views/about/About.vue'
import WxRedirect from '../views/wxRedirect/index.vue'
import { isWechat } from '@/utils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页'
    },
    children:[{
      path: 'download',
      name: 'Download',
      redirect: to => {
        if(!to.query.key) {
          return {
            name: 'Home',
            params: {},
            query: {}
          }
        } else {
          if(isWechat()) {
            return {
              name: 'WxRedirect',
              params: {},
              query: {
                key: to.query.key
              }
            }
          } else {
            return {
              name: 'Home',
              params: {
                key: to.query.key
              },
              query: {}
            }
          }
        }
      }
    }]
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/wxRedirect',
    name: 'WxRedirect',
    component: WxRedirect,
    meta: {
      title: '云活动'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
