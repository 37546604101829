import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss'
import videojs from "video.js";
import "video.js/dist/video-js.css";
import VueTypedJs from 'vue-typed-js'

import { Icon, Menu, Submenu, MenuItem, Row, Col } from "element-ui";

Vue.use(Icon);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(VueTypedJs)

Vue.prototype.$videojs = videojs

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
