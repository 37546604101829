<!-- index.vue -->
<template>
  <div class='wx-redirect'>
    <img v-show="isWechat" src="@/assets/images/wx-redirect.png">
  </div>
</template>

<script>
import { isWechat } from '@/utils'

export default {
  name: 'wxRedirect',
  data() {
    return {
      isWechat
    }
  },
  mounted() {
    this.isWechat = isWechat()
    const downloadKey = this.$route.query?.key
    if(!downloadKey) {
      return
    }
    if(!this.isWechat) {
      this.$router.push({
        name: 'Download',
        query: {
          key: downloadKey
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.wx-redirect {
  width: 100%;
  height: 100%;
  position: relative;
  background: #e1e2e4;
  padding-top: 64px;
  img {
    height: 80%;
    margin: auto;
  }
}
</style>
