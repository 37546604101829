<template>
  <div class="top" id="homeTopContainer">
    <div class="home-video-wrap">
      <!-- <video id="home-video"
        x5-playsinline
        playsinline="true"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        x5-video-orientation="portraint"
        ></video> -->
      <VideoInline videoId="home-video" :videoUrl="videoUrl.home"/>
      <div class="video-mask"></div>
      <div class="home-video-desc">
        <img class="home-video-desc-img" src="@/assets/images/header-img.png"/>
        <div class="home-video-desc-text">海淀区国资委投资的国家高新技术企业</div>
        <div class="home-video-desc-btn" @click="handleContactClick">立即咨询</div>
      </div>
    </div>
    
    <div class="brand-wrap">
      <div class="brand-title">他们都在用</div>
      <div class="brand-list">
        <div class="brand-item" v-for="(item, index) in brandList" :key="index">
          <img :src="require(`@/assets/images/brands/${index + 1}.png`)">
        </div>
      </div>
    </div>
    <div class="build-video-wrap">
      <!-- <video id="build-video"
        x5-playsinline
        playsinline="true"
        webkit-playsinline="true"
        preload
        x-webkit-airplay="true"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        x5-video-orientation="portraint"
      ></video> -->
      <VideoInline videoId="build-video" :videoUrl="videoUrl.build"/>
      <div class="video-mask"></div>
      <div class="build-video-desc">
        <div class="build-video-desc-title">专业元宇宙空间场景制作</div>
        <vue-typed-js 
          :strings="typedStrs"
          :typeSpeed="150"
          :backSpeed="150"
          :backDelay="1200"
          :loop="true"
          :smartBackspace="false"
          :cursorChar="'_'">
          <div class="typing"></div>
        </vue-typed-js>
        <div class="build-video-desc-text-gradient" id="typed-text"></div>
        <div class="build-video-desc-feature">
          <p>超真实场景</p>
          <p>沉浸式体验</p>
          <p>3D内容交互</p>
          <p>即开即用</p>
          <p>弹性扩容</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoInline from "@/components/VideoInline"

export default {
  name: "Top",
  data() {
    return {
      brandList: 14,
      typedStrs: ['元宇宙博物馆', '元宇宙展览', '数字展厅', '元宇宙科技馆', '元宇宙景区', '元宇宙空间', '元宇宙党建展厅'],
      videoUrl: {
        home: "https://alifile.cdn.tuzidong.cn/%E5%AE%98%E7%BD%91%E8%A7%86%E9%A2%91/home-2.mp4",
        build: "https://alifile.cdn.tuzidong.cn/%E5%AE%98%E7%BD%91%E8%A7%86%E9%A2%91/build.mp4"
      }
    }
  },
  components: {
    VideoInline
  },
  mounted() {
  },
  methods: {
    initPlayer(videoKey) {
      if (!videoKey) {
        return;
      }
      videojsOption[videoKey] = JSON.parse(JSON.stringify(defaultVideoOption))
      videojsOption[videoKey].sources[0].src = videoUrl[videoKey];
      player[videoKey] = this.$videojs(
        `${videoKey}-video`,
        videojsOption[videoKey],
        function onPlayerReady() {
          // console.log('onPlayerReady', this);
          // this.play()
        }
      );
    },
    destoryVideo() {
      for(let key in videoUrl) {
        if(!videoUrl[key]) {
          return
        }
        if (player[key]) {
          player[key].dispose()
          player[key] = null
        }
        if (playPromise[key]) {
          playPromise[key] = null
        }
      }
    },
    // 立即咨询
    handleContactClick() {
      let clientWidth = document.body.clientWidth;
      if(clientWidth < 888) {
        // 移动端
        window.location.href = 'tel:400 8675 521';
      } else {
        const contactDom = document.getElementById('contact')
        contactDom.scrollIntoView({
          behavior: 'auto',
          block: 'center'
        })
      }
    },
    // 只有在视窗内才可播放视频
    handleObserverVideo() {
      const ovserverOption = {
        root: null, // 未指定或者为null，则默认为浏览器视窗。
        rootMargin: '0px',
        threshold: 0.25 // 当target元素的在root元素中的可见性超过 25% 的时候，回调函数将会被执行
      }
      const observer = new IntersectionObserver((entries) => {
        for(let i = 0, len = entries.length; i < len; i++) {
          const video_key = entries[i].target.id.split("-")[0]
          if(player[video_key]) {
            if(entries[i].isIntersecting) {
              playPromise[video_key] = player[video_key].play()
            } else {
              playPromise[video_key] && playPromise[video_key].then(() => {
                player[video_key].pause()
              })
            }
          }
        }
      }, ovserverOption);
      const homeVideo = document.getElementById("home-video")
      const buildVideo = document.getElementById("build-video")
      observer.observe(homeVideo)
      observer.observe(buildVideo)
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  position: relative;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto 0;
  .home-video-wrap {
    position: relative;
    width: 100%;
    padding-top: calc(9 / 16 * 100%);
    .video-mask {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(96deg, rgba(0,0,0,0.63) 0%, rgba(0,0,0,00) 26%, rgba(0,0,0,0.00) 74%, rgba(0,0,0,0.63) 100%, rgba(0,0,0,0.63) 100%);
    }
    .home-video-desc {
      position: absolute;
      top: 25%;
      left: 15%;
      user-select: none;
      .home-video-desc-img {
        height: 58px;
        margin-bottom: 36px;
        max-width: 400%;
      }
      .home-video-desc-text {
        font-size: 26px;
        line-height: 36px;
        color: #FFFFFF;
        text-align: left;
        margin-bottom: 30px;
      }
      .home-video-desc-btn {
        background: #9EFF76;
        border-radius: 2px;
        width: 140px;
        height: 46px;
        line-height: 46px;
        font-size: 18px;
        color: #202020;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  
  .brand-wrap {
    width: 86%;
    padding: 40px 80px 10px;
    text-align: center;
    background: #fff;
    border-radius: 11px;
    margin: auto;
    position: relative;
    z-index: 2;
    transform: translateY(-50%);
    // margin-bottom: 100px;
    .brand-title {
      font-size: 22px;
      color: #142722;
      text-align: center;
      margin-bottom: 42px;
      position: relative;
      display: inline-block;
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: -14px;
        bottom: -2px;
        width: 47px;
        height: 5px;
        background: url('~@/assets/images/brand-title-border.png') center / contain no-repeat;
      }
    }
    .brand-list {
      position: relative;
      // margin-left: -13px;
      // margin-right: -13px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .brand-item {
        margin-bottom: 30px;
        width: 156px;
        // padding: 0 13px;
        flex: 0 0 14.28%;
        img {
          margin: 0 auto;
        }
      }
    }
  }
  .build-video-wrap {
    position: relative;
    width: 100%;
    padding-top: calc(9 / 16 * 100%);
    .video-mask {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,0.7);
    }
    .build-video-desc {
      position: absolute;
      top: 50%;
      left: 15%;
      // right: 15%;
      transform: translateY(-50%);
      user-select: none;
      .build-video-desc-title {
        font-size: 52px;
        color: #FFFFFF;
        letter-spacing: 2.49px;
        text-align: left;
        line-height: 67px;
        font-weight: 550;
        margin-bottom: 16px;
      }
      .typed-element {
        font-size: 52px;
        color: #FFFFFF;
        letter-spacing: 2.49px;
        text-align: left;
        line-height: 67px;
        font-weight: 600;
        margin-bottom: 58px;
        .typing{
          background-image: linear-gradient(to right, #72F546, #23BE7F);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        ::v-deep {
          .typed-cursor {
            font-weight: 500;
            background-image: linear-gradient(to right, #72F546, #23BE7F);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .build-video-desc-feature {
        position: relative;
        font-size: 20px;
        color: #FFFFFF;
        letter-spacing: 1.08px;
        text-align: left;
        font-weight: 500;
        line-height: 28px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        p {
          line-height: 28px;
          margin-right: 26px;
          position: relative;
          &:last-child {
            margin-right: 0;
            &::after {
              width: 0;
            }
          }
          &::after {
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #fff;
            margin: 0 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 888px){
  .top {
    .home-video-wrap .home-video-desc {
      transform: scale(0.75) translate(-40%, -20%);
    }
    .brand-wrap {
      width: 86%;
      padding: 20px 20px 10px;
      .brand-title {
        transform: scale(0.75);
        margin-bottom: 32px;
      }
      .brand-list {
        .brand-item {
          flex: 0 0 16%;
          margin-bottom: 24px;
        }
      }
    }
    .build-video-wrap .build-video-desc {
      .build-video-desc-title {
        font-size: 36px;
        line-height: 36px;
      }
      .typed-element {
        font-size: 36px;
        line-height: 36px;
        margin-bottom: 42px;
      }
      .build-video-desc-feature {
        font-size: 16px;
        line-height: 16px;
        p {
          line-height: 16px;
        }
      }
    }
  }
  
}
@media screen and (max-width: 666px){
  .top {
    .home-video-wrap .home-video-desc {
      transform: scale(0.45) translate(-80%, -42%);
    }
    .brand-wrap {
      width: 90%;
      transform: translateY(-25%);
      padding: 20px 20px 10px;
      .brand-title {
        transform: scale(0.75);
        margin-bottom: 24px;
      }
      .brand-list {
        .brand-item {
          flex: 0 0 16%;
          margin-bottom: 18px;
        }
      }
    }
    .build-video-wrap .build-video-desc {
      left: 8%;
      .build-video-desc-title {
        font-size: 24px;
        line-height: 24px;
      }
      .typed-element {
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 32px;
      }
      .build-video-desc-feature {
        font-size: 16px;
        p {
          line-height: 16px;
          margin-right: 10px;
          &::after {
            width: 4px;
            height: 4px;
            margin: 0 2px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 420px){
  .top {
    .home-video-wrap .home-video-desc {
      transform: scale(0.45) translate(-80%, -42%);
    }
    .brand-wrap {
      width: 90%;
      transform: translateY(-10%);
      padding: 10px 20px 10px;
      .brand-title {
        transform: scale(0.65);
        margin-bottom: 16px;
      }
      .brand-list {
        .brand-item {
          flex: 0 0 20%;
          margin-bottom: 12px;
        }
      }
    }
    .build-video-wrap .build-video-desc {
      left: 8%;
      .build-video-desc-title {
        font-size: 20px;
        line-height: 20px;
      }
      .typed-element {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 24px;
      }
      .build-video-desc-feature {
        font-size: 12px;
        line-height: 16px;
        p {
          line-height: 16px;
          margin-right: 10px;
          &::after {
            width: 4px;
            height: 4px;
            margin: 0 2px;
          }
        }
      }
    }
  }
}
</style>