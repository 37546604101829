import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    WeixinJSBridgeReady: false
  },
  getters: {
  },
  mutations: {
    SET_WeixinJSBridgeReady: (state, WeixinJSBridgeReady) => {
      state.WeixinJSBridgeReady = WeixinJSBridgeReady
    },
  },
  actions: {
  },
  modules: {
  }
})
