<template>
  <div class="container" id="homeContainer">
    <Top/>
    <Middle/>
    <Bottom/>
  </div>
</template>

<script>
import Top from "./components/Top"
import Middle from "./components/Middle"
import Bottom from "./components/Bottom"
export default {
  name: "Home",
  components: {
    Top,
    Middle,
    Bottom
  },
  data() {
    return {

    }
  },
  watch: {
    $route: {
      handler(val) {
        if(val.redirectedFrom == '/download' && !val.params.key) {
          this.$nextTick(() => {
            const experienceDownloadDom = document.getElementById('experienceDownload')
            experienceDownloadDom.scrollIntoView({
              behavior: 'auto',
              block: 'center'
            })
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
</style>