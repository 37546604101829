<template>
  <div class="bottom">
    <div class="footer-wrap">
      <div class="left-wrap">
        <div class="logo">
          <img src="@/assets/images/logo.png">
          <div class="label">云活动</div>
        </div>
        <div class="introduce">
          <p>我们致力于</p>
          <p>用元宇宙的方式打造更有趣的线上体验</p>
        </div>
        <div class="divider"></div>
        <div class="service">
          <p>客服热线：4008675521</p>
          <p>周一至周五 9:30-20:00</p>
        </div>
      </div>
      <div class="right-wrap">
        <div class="wx-video">
          <div class="label">微信视频号</div>
          <img class="qr" src="@/assets/images/qr-wx-video.png">
        </div>
        <div class="copyright"><span @click="handleOpenBeian">京ICP备20012739号</span> © 版权所有©2021挖开兔子洞（北京）科技有限公司</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleOpenBeian() {
      window.open("https://beian.miit.gov.cn/", "_blank")
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom {
  background: #0D191D;
  .footer-wrap {
    width: 100%;
    // height: 350px;
    position: relative;
    padding: 76px 20px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // flex-wrap: wrap;
    max-width: 1200px;
    margin: auto;
    .left-wrap {
      .logo {
        display: flex;
        align-items: center;
        margin-bottom: 45px;
        img {
          width: 34px;
          height: 34px;
          margin-right: 8px;
        }
        .label {
          font-size: 18px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 25px;
        }
      }
      .introduce {
        font-size: 14px;
        color: #929495;
        letter-spacing: 0;
        text-align: left;
        line-height: 24px;
      }
      .divider {
        width: 150%;
        height: 1px;
        background: #2F3436;
        margin: 21px 0;
      }
      .service {
        font-size: 12px;
        color: #949B9D;
        letter-spacing: 0;
        text-align: left;
        line-height: 24px;
      }
    }
    .right-wrap {
      text-align: right;
      .wx-video {
        margin-bottom: 50px;
        .label {
          font-size: 14px;
          color: #949B9D;
          letter-spacing: 0;
          text-align: center;
          width: 120px;
          margin-left: auto;
          margin-bottom: 10px;
        }
        .qr {
          border: 5px solid rgba(255,255,255,1);
          border-radius: 3px;
          width: 120px;
          height: 120px;
          margin-left: auto;
        }
      }
      .copyright {
        font-size: 12px;
        color: #646666;
        letter-spacing: 0;
        text-align: right;
        line-height: 24px;
        span {
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .bottom .footer-wrap {
    padding: 36px 20px 50px;
  }
}
</style>