<template>
  <div :class="navbarType == 'pc' ? 'navbar navbar-pc' : 'navbar navbar-mobile'">
    <template v-if="navbarType == 'pc'">
      <div class="left-wrap">
        <div class="logo">
          <img src="@/assets/images/website-logo.png" />
        </div>
        <div class="menu-list">
          <div v-for="(item, index) in menuList" :key="index"
            :class="index == activeMenuIndex ? 'menu-item active' : 'menu-item'" @click="handleClickMenuItem(item.key)">
            <template v-if="!item.children || item.children.length == 0">{{
              item.label
            }}</template>
            <template v-else>
              <div @mouseenter="handleShowChildMenu(item.key)" @mouseleave="handleHiddenChildmenu(item.key)">
                <div>{{ item.label }}</div>
                <div :ref="`childMenu_${item.key}`" class="child-menu">
                  <div class="child-menu-item" v-for="(i, j) in item.children" :key="j"
                    @click="handleClickMenuItem(i.key)">
                    {{ i.label }}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="right-wrap">
        <div class="phone" @click="handleContactClick">{{ contactPhone }}</div>
        <div class="manage-btn" @click="handleJumpManage">管理端登陆</div>
      </div>
    </template>
    <template v-else>
      <div class="menu-wrap">
        <img class="logo" src="@/assets/images/logo.png" />
        <div class="menu-phone-wrap">
          <div class="phone h5-phone" @click="callPhone">
            {{ contactPhone }}
          </div>
        </div>
        <div class="header__bars" @click="hanldeShowMobileMenu">
          <div class="header__bars-bar header__bars-bar-1"></div>
          <div class="header__bars-bar header__bars-bar-2"></div>
          <div class="header__bars-bar header__bars-bar-3"></div>
        </div>
        <div class="menu-list" ref="mobileMenuList">
          <div class="menu-top">
            <img class="logo" src="@/assets/images/logo.png" />
            <i class="el-icon-close" @click="hanldeHiddenMobileMenu"></i>
          </div>
          <el-menu default-active="0" mode="vertical">
            <div v-for="(item, index) in menuList" :key="index">
              <el-menu-item v-if="!item.children || item.children.length == 0" :index="index.toString()"
                @click="handleClickMenuItem(item.key)">{{ item.label }}</el-menu-item>
              <el-submenu v-else :index="index.toString()">
                <template slot="title">{{ item.label }}</template>
                <el-menu-item v-for="(i, j) in item.children" :key="j" :index="`${index}-${j}`"
                  @click="handleClickMenuItem(i.key)">{{ i.label }}</el-menu-item>
              </el-submenu>
            </div>
          </el-menu>
          <div class="menu-bottom" @click="handleJumpManage">
            管理端登录<i class="el-icon-right"></i>
          </div>
          <div class="phone-wrap">
            <div class="phone h5-phone" @click="callPhone">400 8675 521</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  systemUrl,
  shopUrl,
  virtualSceenDocUrl,
  virtualHumanDocUrl,
} from "@/config/index";

export default {
  name: "Navbar",
  data() {
    return {
      navbarType: "pc",
      menuList: [
        {
          label: "首页",
          key: "home",
        },
        {
          label: "案例体验",
          key: "shop",
        },
        {
          label: "开发者文档",
          key: "doc",
          children: [
            {
              label: "虚拟场景文档",
              key: "virtualSceenDoc",
            },
            {
              label: "数字虚拟人文档",
              key: "virtualHumanDoc",
            },
          ],
        },
        {
          label: "下载",
          key: "download",
        },
        {
          label: "关于我们",
          key: "about",
        },
      ],
      activeMenuIndex: 0,
      contactPhone: "15684701327", // 400 8675 521
    };
  },
  mounted() {
    this.$nextTick(() => {
      let clientWidth = document.body.clientWidth;
      this.toggleNavbarType(clientWidth);
    });
    window.addEventListener("resize", () => {
      let clientWidth = document.body.clientWidth;
      this.toggleNavbarType(clientWidth);
    });
  },
  watch: {
    $route: {
      handler(val) {
        if (val.path == "/about") {
          this.activeMenuIndex = 4;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    toggleNavbarType(clientWidth) {
      if (clientWidth < 888) {
        this.navbarType = "mobile";
      } else {
        this.navbarType = "pc";
      }
    },
    handleShowChildMenu(key) {
      this.$refs[`childMenu_${key}`][0].className = "child-menu active";
    },
    handleHiddenChildmenu(key) {
      this.$refs[`childMenu_${key}`][0].className = "child-menu";
    },
    handleClickMenuItem(key) {
      const appContainerDom = document.getElementById("app");
      switch (key) {
        case "home":
          this.activeMenuIndex = 0;
          if (this.$route.path != "/") {
            this.$router.push("/");
          }
          appContainerDom.scrollTop = 0;
          this.hanldeHiddenMobileMenu();
          break;
        case "shop":
          this.handleOpenNewTab(shopUrl);
          break;
        case "virtualSceenDoc":
          this.handleOpenNewTab(virtualSceenDocUrl);
          break;
        case "virtualHumanDoc":
          this.handleOpenNewTab(virtualHumanDocUrl);
          break;
        case "download":
          this.activeMenuIndex = 3;
          if (this.$route.path != "/") {
            this.$router.push("/");
          }
          this.$nextTick(() => {
            const experienceDownloadDom =
              document.getElementById("experienceDownload");
            experienceDownloadDom.scrollIntoView({
              behavior: "auto",
              block: "center",
            });
          });
          this.hanldeHiddenMobileMenu();
          break;
        case "about":
          this.activeMenuIndex = 4;
          if (this.$route.path != "/about") {
            this.$router.push("/about");
          }
          appContainerDom.scrollTop = 0;
          this.hanldeHiddenMobileMenu();
          break;
        default:
          break;
      }
    },
    handleOpenNewTab(url) {
      window.open(url, "_blank");
    },
    // 管理端登陆按钮点击
    handleJumpManage() {
      this.handleOpenNewTab(systemUrl);
    },
    hanldeShowMobileMenu() {
      if (this.$refs.mobileMenuList) {
        this.$refs.mobileMenuList.style.right = 0;
      }
    },
    hanldeHiddenMobileMenu() {
      if (this.$refs.mobileMenuList) {
        this.$refs.mobileMenuList.style.right = "100%";
      }
    },
    // 立即咨询
    handleContactClick() {
      const contactDom = document.getElementById("contact");
      contactDom.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    },
    callPhone() {
      window.location.href = `tel:${this.contactPhone}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 60px;
  background-image: linear-gradient(180deg, #010101 1%, rgba(0, 0, 0, 0) 100%);
  padding: 0 70px 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #ffffff;

  &.navbar-pc {
    .left-wrap {
      flex: 1;
      display: flex;
      align-items: center;

      .logo {
        width: 40px;
        height: 30px;
        margin-right: 90px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .menu-list {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        .menu-item {
          padding: 0 30px;
          cursor: pointer;
          position: relative;
          height: 60px;
          line-height: 60px;

          &:hover {
            color: #5ae55a;
          }

          &.active {
            color: #61f460;
            font-weight: bold;
          }

          .child-menu {
            position: absolute;
            top: 60px;
            left: 50%;
            transform: translateX(-50%);
            min-width: 120px;
            text-align: left;
            background: #0d191d;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
            border-radius: 1px;
            transition: all 0.4s ease;
            height: 0;
            padding: 0;

            &.active {
              height: auto;
              padding: 8px 0;

              &::before {
                width: 68px;
              }

              .child-menu-item {
                display: block;
                height: 32px;
                line-height: 32px;
              }
            }

            &::before {
              content: "";
              width: 0;
              height: 2px;
              border-radius: 1.5px;
              background: #5ae55a;
              position: absolute;
              top: -4px;
              left: 50%;
              transform: translateX(-50%);
              transition: all 0.4s ease;
            }

            .child-menu-item {
              display: none;
              padding-left: 16px;
              padding-right: 16px;
              word-break: keep-all;
              color: #ffffff;

              &:hover {
                color: #5ae55a;
                background: rgba(90, 229, 90, 0.11);
              }
            }
          }
        }
      }
    }

    .right-wrap {
      display: flex;
      align-items: center;

      .manage-btn {
        cursor: pointer;
      }
    }
  }

  &.navbar-mobile {
    // background: #ffffff;
    padding: 0 24px 0 36px;
    height: 70px;

    .menu-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;

      .logo {
        width: 45px;
        height: 45px;
      }

      .header__bars {
        width: 50px;
        height: 50px;
        transition: all 0.25s ease;
        cursor: pointer;
        padding: 10px;
        border: 1px solid transparent;
        margin-top: 4px;

        .header__bars-bar {
          height: 2px;
          display: block;
          background-color: #fff;
          margin: 3px 0;
          float: right;

          &.header__bars-bar-1 {
            width: 100%;
          }

          &.header__bars-bar-2 {
            width: 70%;
          }

          &.header__bars-bar-3 {
            width: 40%;
          }
        }
      }

      .menu-list {
        position: absolute;
        right: 100%;
        top: 0;
        height: 100vh;
        width: 100vw;
        background: #f5f7fa;
        transition: all 0.4s ease;

        .menu-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 70px;
          padding: 0 24px 0 36px;
          background: #fff;

          i {
            font-size: 28px;
            color: #666;
            cursor: pointer;
          }
        }

        .menu-bottom {
          background: #fafafa;
          font-size: 16px;
          color: #080b1d;
          height: 64px;
          line-height: 64px;
          // border-top: 1px solid #C7C7C7;
          text-align: right;
          padding-right: 40px;
          user-select: none;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: #c7c7c7;
            transform: scaleY(0.5);
          }

          i {
            margin-left: 12px;
          }
        }

        .el-menu {
          max-height: calc(100% - 70px - 64px);
          overflow-y: auto;

          ::v-deep {

            .el-menu-item,
            .el-submenu__title {
              background: #fafafa;
              color: #474747;
              font-size: 18px;
              height: 82px;
              line-height: 82px;

              // border-top: 1px solid #C7C7C7;
              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: #c7c7c7;
                transform: scaleY(0.5);
              }

              &::before {
                content: "";
                display: inline-block;
                width: 16px;
              }

              i {
                right: 36px;
              }
            }

            .el-submenu {
              &.is-opened {
                .el-submenu__title {
                  background: #5b7686;
                  color: #fff;

                  i {
                    color: #fff;
                    right: 36px;
                  }
                }

                .el-menu-item {
                  border-color: #fff;
                }
              }

              .el-submenu__title {
                i {
                  font-size: 18px;
                }
              }

              .el-menu-item {
                background: #ebebeb;
                height: 64px;
                line-height: 64px;
              }
            }
          }
        }
      }
    }
  }
}

.menu-phone-wrap {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;

  .phone.h5-phone {
    margin-right: 0;
    padding: 10px 16px;
    font-size: 16px;
    line-height: 16px;
    padding: 4px 8px;
  }
}

.phone-wrap {
  position: absolute;
  bottom: 48px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 40px;
}

.phone {
  font-size: 20px;
  color: #ed4042;
  letter-spacing: 0;
  text-align: left;
  font-weight: bold;
  margin-right: 24px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background: #f9d11f;
  padding: 4px 8px;
  border-radius: 2px;
  animation: shakeX 3s 0s infinite;
  user-select: none;

  &.h5-phone {
    margin-right: 0;
    padding: 10px 16px;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    background: url("~@/assets/images/icon-phone-2.png") center/100% 100% no-repeat;
  }

  @keyframes shakeX {

    from,
    50%,
    to {
      transform: translate3d(0, 0, 0);
    }

    5%,
    15%,
    25%,
    35%,
    45% {
      transform: translate3d(-6px, 0, 0);
    }

    10%,
    20%,
    30%,
    40% {
      transform: translate3d(6px, 0, 0);
    }
  }
}
</style>
